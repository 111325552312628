exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alquiler-js": () => import("./../../../src/pages/alquiler.js" /* webpackChunkName: "component---src-pages-alquiler-js" */),
  "component---src-pages-alquiler-temporario-js": () => import("./../../../src/pages/alquiler-temporario.js" /* webpackChunkName: "component---src-pages-alquiler-temporario-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-emprendimientos-[id]-js": () => import("./../../../src/pages/emprendimientos/[id].js" /* webpackChunkName: "component---src-pages-emprendimientos-[id]-js" */),
  "component---src-pages-emprendimientos-index-js": () => import("./../../../src/pages/emprendimientos/index.js" /* webpackChunkName: "component---src-pages-emprendimientos-index-js" */),
  "component---src-pages-favoritos-js": () => import("./../../../src/pages/favoritos.js" /* webpackChunkName: "component---src-pages-favoritos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-novedades-[id]-[slug]-js": () => import("./../../../src/pages/novedades/[id]/[slug].js" /* webpackChunkName: "component---src-pages-novedades-[id]-[slug]-js" */),
  "component---src-pages-novedades-index-js": () => import("./../../../src/pages/novedades/index.js" /* webpackChunkName: "component---src-pages-novedades-index-js" */),
  "component---src-pages-propiedad-[operation]-[id]-js": () => import("./../../../src/pages/propiedad/[operation]/[id].js" /* webpackChunkName: "component---src-pages-propiedad-[operation]-[id]-js" */),
  "component---src-pages-questions-js": () => import("./../../../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-tasaciones-js": () => import("./../../../src/pages/tasaciones.js" /* webpackChunkName: "component---src-pages-tasaciones-js" */),
  "component---src-pages-tokko-[id]-js": () => import("./../../../src/pages/tokko/[id].js" /* webpackChunkName: "component---src-pages-tokko-[id]-js" */),
  "component---src-pages-venta-js": () => import("./../../../src/pages/venta.js" /* webpackChunkName: "component---src-pages-venta-js" */)
}

